<template>
  <div>
    <div class="main-title">内容纠错列表</div>
    <div class="content-list-page page-content">
      <div class="padding-t-sm padding-b-sm">
        <span class="fl-l">
          <el-input
            v-model="searchData.userId"
            size="mini"
            placeholder="用户id"
          />
        </span>
        <span class="margin-l-sm fl-l">
          <el-input
            v-model="searchData.nickName"
            size="mini"
            placeholder="用户昵称"
          />
        </span>
        <span class="margin-l-sm fl-l">
          <el-input
            v-model="searchData.reviewerId"
            size="mini"
            placeholder="审核人id"
          />
        </span>
        <span class="margin-l-sm fl-l">
          <el-input
            v-model="searchData.reviewerNickName"
            size="mini"
            placeholder="审核人昵称"
          />
        </span>

        <span class="margin-l-sm fl-l">
          <el-select
            v-model="searchData.status"
            clearable
            placeholder="选择状态"
            size="mini"
          >
            <el-option label="待审核" :value="1"></el-option>
            <el-option label="有效" :value="2"></el-option>
            <el-option label="无效" :value="3"></el-option>
          </el-select>
        </span>

        <span class="margin-l-sm fl-l">
          <el-input
            v-model="searchData.checkCourseName"
            size="mini"
            placeholder="课程名称"
          />
        </span>

        <span class="margin-l-sm fl-l">
          <el-input
            v-model="searchData.checkCoursePlanName"
            size="mini"
            placeholder="计划名称"
          />
        </span>
        <span class="margin-l-sm fl-l">
          <el-input
            v-model="searchData.checkCoursePlanClassName"
            size="mini"
            placeholder="小节名称"
          />
        </span>
        <span class="margin-l-sm fl-l">
          <el-date-picker
            size="mini"
            v-model="searchData.datePick"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
          >
          </el-date-picker>
        </span>

        <span class="margin-l-sm fl-l">
          <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="search"
            >搜索</el-button
          >
        </span>

        <span class="fl-r">
          <router-link to="/excerpt/add" class="router-link">
            <el-button type="primary" size="small">新增</el-button>
          </router-link>
        </span>

        <div style="clear: both"></div>
      </div>

      <div class="table-content">
        <el-table
          :header-cell-style="{ padding: 0 }"
          ref="singleTable"
          :data="tableData"
          highlight-current-row
          v-loading="loading"
          style="width: 100%; background: #fff"
        >
          <el-table-column label="ID" property="id" align="center" width="80">
          </el-table-column>

          <el-table-column property="user_name" align="center" label="用户名称">
          </el-table-column>

          <el-table-column align="center" label="用户头像">
            <template slot-scope="scope">
              <el-image class="table-item-img" :src="scope.row.user_avatar_url">
              </el-image>
            </template>
          </el-table-column>

          <el-table-column
            property="course_name"
            align="center"
            label="课程名称"
          >
          </el-table-column>

          <el-table-column property="plan_name" align="center" label="计划名称">
          </el-table-column>
          <el-table-column
            property="class_name"
            align="center"
            label="小节名称"
          >
          </el-table-column>

          <el-table-column property="content" align="center" label="内容">
          </el-table-column>

          <el-table-column align="center" label="状态">
            <template slot-scope="scope">
              <span>
                {{
                  scope.row.status == 1
                    ? "待审核"
                    : scope.row.status == 2
                    ? "有效"
                    : "无效"
                }}</span
              >
            </template>
          </el-table-column>

          <el-table-column
            property="reviewer_name"
            align="center"
            label="审核人"
          >
          </el-table-column>

          <el-table-column
            property="reviewed_at"
            align="center"
            label="审核时间"
          >
          </el-table-column>

          <el-table-column align="center" label="操作">
            <template slot-scope="scope">
              <span class="top">
                <el-link type="primary" @click="audit(scope.row)">审核</el-link>
              </span>
              -
              <span class="top">
                <el-link type="primary" @click="details(scope.row)"
                  >详情</el-link
                >
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="table-batch">
        <span class="fl-r">
          <el-pagination
            small
            background
            :current-page="page"
            :page-sizes="[10, 20]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="setPageSize"
            @current-change="setPage"
            :total="total"
          >
          </el-pagination>
        </span>
        <div style="clear: both"></div>
      </div>
    </div>

    <el-dialog
      title="审核"
      append-to-body
      :visible.sync="dialogVisible"
      width="40%"
    >
      <div style="padding: 10px">
        <el-form :model="form" ref="form" label-width="100px">
          <el-form-item label="审核结果：">
            <el-radio v-model="form.isPass" :label="true">通过</el-radio>
            <el-radio v-model="form.isPass" :label="false">失败</el-radio>
          </el-form-item>
          <el-form-item
            label="失败理由:"
            v-if="form.isPass == false"
            prop="reason"
            :rules="[
              { required: true, message: '请输入失败的理由', trigger: 'blur' },
              { max: 225, message: '长度在225个字符以内', trigger: 'blur' },
            ]"
          >
            <el-input
              type="textarea"
              style="width: calc(100% - 100px)"
              placeholder="请输入内容"
              v-model="form.reason"
            >
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">确定</el-button>
            <el-button
              @click="
                dialogVisible = false;
                form = { isPass: true };
              "
              >取消</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>

    <el-dialog
      title="详情"
      append-to-body
      :visible.sync="particularsDialogVisible"
      width="40%"
    >
      <div
        style="padding: 10px; line-height: 26px"
        v-loading="particularsLoading"
      >
        <div class="user-info" style="justify-content: space-between">
          <div class="user-info">
            <el-avatar :src="particulars.user_avatar_url"></el-avatar>
            <div style="padding: 0px 10px">
              用户昵称：{{ particulars.user_name }}
            </div>
          </div>
          <div class="user-info" v-if="particulars.reviewer">
            <el-avatar :src="particulars.reviewer_avatar_url"></el-avatar>
            <div style="padding: 0px 10px">
              <div>审核者：{{ particulars.reviewer }}</div>
              <div>审核时间：{{ particulars.reviewed_at }}</div>
              <div v-if="particulars.reason != ''">
                无效原因：{{ particulars.reason }}
              </div>
            </div>
          </div>
        </div>
        <div><span>纠错内容：</span>{{ particulars.content }}</div>
        <div><span>课程：</span>{{ particulars.course_name }}</div>
        <div><span>计划：</span>{{ particulars.plan_name }}</div>
        <div><span>小节：</span>{{ particulars.class_name }}</div>
        <div>
          <span>状态：</span>
          {{
            particulars.status == 1
              ? "待审核"
              : particulars.status == 2
              ? "有效"
              : "无效"
          }}
        </div>
        <div>
          <div v-for="(item, index) in particulars.pics" :key="index">
            <el-image :src="imageUrl + item"></el-image>
          </div>
        </div>
        <div style="margin:10px 0px;">
          <el-button @click="particularsDialogVisible = false">取消</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { config } from "../../../js/config";

export default {
  name: "list",
  data() {
    return {
      page: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      imageUrl: config.imageUrl,
      searchData: {},
      tableData: [],
      dialogVisible: false,
      form: {},
      particulars: {},
      particularsLoading: false,
      particularsDialogVisible: false,
    };
  },
  methods: {
    ...mapActions("errorList", [
      "correctList",
      "verifyCorrect",
      "detailCorrect",
    ]),
    async details(row) {
      this.particularsDialogVisible = true;
      this.particularsLoading = true;
      const { data } = await this.detailCorrect({ id: row.id });
      this.particulars = data;
      this.particularsLoading = false;
    },
    audit(row) {
      this.dialogVisible = true;
      this.form.id = row.id;
    },
    async onSubmit() {
      const { res_info } = await this.verifyCorrect(this.form);
      if (res_info != "ok") return;
      this.$message.success("审核成功");
      this.dialogVisible = false;
      this.getList();
    },
    search() {
      this.tableData = [];
      this.page = 1;
      this.getList();
    },
    async getList() {
      this.loading = true;
      let form = {
        ...this.searchData,
        page: this.page,
        pageSize: this.pageSize,
      };
      const { data } = await this.correctList(form);
      this.tableData = data.list;
      this.total = data.total;
      this.loading = false;
    },
    setPageSize(pageSize) {
      this.pageSize = pageSize;
      this.getList();
    },
    setPage(page) {
      this.page = page;
      this.getList();
    },
  },
  async mounted() {
    this.getList();
  },
};
</script>

<style>
.el-table .cell span.el-tag {
  margin-right: 5px;
  display: inline-block;
}
.user-info {
  display: flex;
  align-items: center;
}
</style>
